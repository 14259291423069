<template>
  <div>
    <a-modal
      v-model="isShow"
      width="400px"
      centered
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="编辑手机号"
      @ok="handleSubmit"
    >
      <a-form
        class="custom-compact-form"
        :form="form"
        :label-col="{ span: 6 }"
        :wrapper-col="{ span: 16 }"
        @submit="handleSubmit"
      >
        <a-form-item label="旧手机号">
          <span>{{ $props.oldPhoneNumber }}</span>
        </a-form-item>
        <a-form-item label="新手机号">
          <a-input
            v-decorator="['phone_number', {
              normalize: this.$lodash.trim,
              rules: [
                { required: true, message: '请输入手机号码' },
                { pattern: /^1[3-9]\d{9}$/, message: '手机号码格式不正确' }
              ],
              validateTrigger: 'blur'
            }]"
            placeholder="请输入手机号"
            autocomplete="off"
          />
        </a-form-item>
        <a-form-item label="验证码">
          <div class="custom-flex">
            <a-input
              v-decorator="['sms_captcha', {
                normalize: this.$lodash.trim,
                rules: [
                  { required: true, message: '请输入验证码' },
                ],
                validateTrigger: 'blur'
              }]"
              placeholder="请输入验证码"
              autocomplete="off"
            />
            <a-button type="primary" @click="getSmsCaptcha" :disabled="disabled" class="margin-left-10">
              {{ btnTitle }}
            </a-button>
          </div>
        </a-form-item>
      </a-form>
    </a-modal>
  </div>
</template>

<script>
import { updateUserSelfPhoneNumber, verificationPhoneNumber } from '@/api/user'
import { sendSmsCaptcha } from '@/api/sms_captcha'
export default {
  name: 'EditUserSelfPhoneNumber',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    id: {
      type: Number,
      required: true
    },
    identification: {
      type: String,
      required: true
    },
    oldPhoneNumber: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'edit_full_name' }),
      submitting: false,
      disabled: false,
      btnTitle: '获取验证码'
    }
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    getSmsCaptcha(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }
      this.form.validateFields(['phone_number'], (err, values) => {
        if (!err) {
          if (values) {
            this.submitting = true
            if (values.phone_number === this.$props.oldPhoneNumber) {
              this.$message.warning('手机号与原号码一致，请重新输入')
              this.submitting = false
              return
            }

            verificationPhoneNumber(values).then((res) => {
              if (res.code === 30003) {
                this.submitting = false
                return
              }
              if (res.code === 10000) {
                this.submitting = false
                return
              }
              sendSmsCaptcha(values).then((res) => {
                if (res.code === 0) {
                  this.validateBtn()
                }
              })
              this.submitting = false
            })
          }
        }
      })
    },

    validateBtn() {
      // 倒计时
      let time = 60
      const timer = setInterval(() => {
        if (time === 0) {
          clearInterval(timer)
          this.disabled = false
          // eslint-disable-next-line no-unused-expressions
          this.btnTitle = '获取验证码'
        } else {
          this.btnTitle = time + '秒后重试'
          this.disabled = true
          time -= 1
        }
      }, 1000)
    },

    handleConfirmBlur(e) {
      const value = e.target.value
      this.confirmDirty = this.confirmDirty || !!value
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          const data = {
            ...values
          }
          updateUserSelfPhoneNumber(this.id, data).then((res) => {
            if (res.code === 0) {
              // 告知父组件已完成
              this.isShow = false
              this.$emit('completed')
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
  .margin-left-10 {
    margin-left: 10px;
  }
</style>
